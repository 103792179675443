import { productFilters } from "./datalayer";

const pushDataLayer = (filtersData) => {
  const { filters, slug } = filtersData;
  Object.entries(filters).forEach(([type, events]) => {
    const filtersEvent = {
      event: `Filters-${type}`,
      slug: slug,
      filters: events
    }
    productFilters(filtersEvent);
  });
  filtersData.filters = {};
}

const hasEvents = filtersData => !!Object.keys(filtersData.filters)?.length;

export const productFilterEvent = (params) => {
  const filtersData = { slug: params.location.pathname, filters: {} };

  const listenerFiltersEvent = e => {
    const { type, ...detail } = e.detail;
    if (!filtersData.filters[type]) {
      filtersData.filters[type] = [];
    }
    filtersData.filters[type].push(detail);
  };
  document.addEventListener("FilterChange", listenerFiltersEvent);

  const listenerVisibilityChange = e => {
    if (document.visibilityState === 'hidden' && hasEvents(filtersData)) {
      pushDataLayer(filtersData);
    }
  };
  document.addEventListener('visibilitychange', listenerVisibilityChange);

  const listenerRouteChange = e => {
    if (hasEvents(filtersData)) {
      pushDataLayer(filtersData);
    }
    filtersData.slug = e.detail.slug;
  };
  document.addEventListener("RouteChange", listenerRouteChange);

  const listenerClearFilters = e => {
    if (hasEvents(filtersData)) {
      pushDataLayer(filtersData);
    }
  };
  document.addEventListener("ClearFilter", listenerClearFilters);

  const listenerPageHide = e => {
    if (e.persisted) {
      if (hasEvents(filtersData)) {
        pushDataLayer(filtersData);
      }
    }
  };
  window.addEventListener("pagehide", listenerPageHide, false);
}
