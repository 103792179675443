/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import "./src/css/custom.scss";
import { YoutubePageWrapper, YoutubeProvider } from "./src/context/youtube";
import { UserProvider } from "./src/context/user";
import { CartPageWrapper, CartProvider } from "./src/context/cart";
import { onRouteUpdate as updateScrollOnRouteUpdate } from "./src/hooks/use-keep-scroll";
import { initCookiePolicy, initPrivacyPolicy } from "./src/utility/onetrust";
// Per scroll-link
import smoothscroll from "smoothscroll-polyfill";
import { productFilterEvent } from "./src/utility/filters-event";
import { hasEcommerce } from "./src/utility/ecommerce";
import { HelmetProvider } from "react-helmet-async";
import { eventuallyRemoveReevooScript } from "./src/utility/reevoo";

smoothscroll.polyfill();

const attachShadow = HTMLElement.prototype.attachShadow;

HTMLElement.prototype.attachShadow = function (option) {
  const sh = attachShadow.call(this, option);
  //console.info("%s shadow attached to %s", option.mode, this);
  const event = new CustomEvent("attach-shadow", {
    detail: {
      element: this,
    },
  });
  document.dispatchEvent(event);
  //add a MutationObserver here
  return sh;
};

const queryClient = new QueryClient();

export const onRouteUpdate = (params) => {
  updateScrollOnRouteUpdate(params);
  initCookiePolicy();
  initPrivacyPolicy();

  // Non eseguire sul primo caricamento, viene fatto da onInitialClientRender
  if (params.prevLocation) {
    if (params.location.pathname !== params.prevLocation.pathname) {
      eventuallyRemoveReevooScript();
      const event = new CustomEvent("RouteChange", {
        detail: {
          slug: params.location.pathname,
        },
      });
      document.dispatchEvent(event);
    }
  } else {
    if (params.location.search) {
      const parameters = new URLSearchParams(params.location.search);
      const utmCampaign = parameters.get("utm_campaign");
      const utmMedium = parameters.get("utm_medium");
      const utmSource = parameters.get("utm_source");
      const utmContent = parameters.get("utm_content");
      const utmTerm = parameters.get("utm_term");

      if (utmCampaign) {
        setUtmValue(utmCampaign, "campaign");
      }
      if (utmContent) {
        setUtmValue(utmContent, "content");
      }
      if (utmSource) {
        setUtmValue(utmSource, "source");
      }
      if (utmMedium) {
        setUtmValue(utmMedium, "medium");
      }
      if (utmTerm) {
        setUtmValue(utmTerm, "term");
      }
    }

    productFilterEvent(params);
  }
};

const setUtmValue = (value, label) => {
  // console.log(label)
  const utmStorage = sessionStorage.getItem(`utm_${label}`);
  if (!utmStorage) {
    sessionStorage.setItem(`utm_${label}`, value);
  }
};

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <YoutubeProvider>
        <UserProvider>
          {hasEcommerce ? <CartProvider>{element}</CartProvider> : element}
        </UserProvider>
      </YoutubeProvider>
    </HelmetProvider>
  </QueryClientProvider>
);

export const wrapPageElement = ({ element }) => {
  return (
    <YoutubePageWrapper>
      {hasEcommerce ? <CartPageWrapper>{element}</CartPageWrapper> : element}
    </YoutubePageWrapper>
  );
};
