import { graphql, useStaticQuery } from "gatsby";
import React, { useCallback, useEffect, useRef } from "react";
import Modal from "./modal";

const CartModal = ({ show, onHide, msg, msgKey, icon = "tick", cartUrl, loading }) => {
  const ref = useRef(null);

  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        goToCart
        continueOnTheShop
        anErrorAsOccurred
        itemAddedToCart
        noProductsInCart
      }
    }
  `);

  useEffect(() => {
    if (show && ref.current) {
      ref.current.focus();
    }
  }, [show]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        onHide();
      }
    },
    [onHide]
  );

  return (
    <Modal>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions */}
      <div
        ref={ref}
        className={`cart-modal${show ? " show" : ""}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={show ? 0 : -1}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        role="dialog"
        aria-hidden={!show || !cartUrl}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
        onKeyDown={onKeyDown}
      >
        {loading ? (
          <div className="cart-modal__loading">
            <div className="spinner-border"></div>
          </div>
        ) : (
          <div className="cart-modal__dialog" role="document">
            <div className="text-center">
              {icon ? (
                <div className={`cart-modal__icon cart-modal__icon--${icon}`}>
                  <span className={`icon-${icon}`}></span>
                </div>
              ) : null}
              <div className="cart-modal__title mt-3 mt-md-4">
                {msgKey && labelManagerLabels[msgKey] ? labelManagerLabels[msgKey] : msg}
              </div>
              {cartUrl ? (
                <div className="mt-3 mt-md-4">
                  <a href={cartUrl} className="btn-link">
                    {labelManagerLabels.goToCart}
                  </a>
                </div>
              ) : null}
              <button className="cart-modal__close mt-3 mt-md-4" onClick={onHide}>
                {labelManagerLabels.continueOnTheShop}
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CartModal;
