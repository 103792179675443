import { useState, useEffect, useCallback, useMemo } from "react";
import { navigate } from "gatsby";

const parseQuery = (query) => {
  const urlSearchParams = new URLSearchParams(query || "");
  const currentQuery = {};
  for (let [key, value] of urlSearchParams.entries()) {
    currentQuery[key] = currentQuery[key]
      ? Array.isArray(currentQuery[key])
        ? currentQuery[key].concat(value)
        : [currentQuery[key], value]
      : value;
  }
  return currentQuery;
};

const stringifyQuery = (queryObj) => {
  const newQsParams = new URLSearchParams();
  for (let key in queryObj) {
    if (Array.isArray(queryObj[key])) {
      queryObj[key].forEach((val) => newQsParams.append(key, val));
    } else {
      newQsParams.append(key, queryObj[key]);
    }
  }
  return newQsParams.toString();
};

const useKeepScroll = (location) => {
  const [newQuery, setNewQuery] = useState(null);
  const [mounted, setMounted] = useState(false);
  const currentQuery = useMemo(
    () => parseQuery(mounted && location.search ? location.search.slice(1) : ""),
    [mounted, location.search]
  );

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (newQuery) {
      const scrollPos = (document.scrollingElement || document.documentElement || document.body)
        .scrollTop;
      window.sessionStorage.setItem(`keep-scroll-qs-${location.pathname}`, scrollPos);
      const newQs = stringifyQuery(newQuery);
      navigate(`${newQs.length ? `?${newQs}` : "?"}`);
    }
  }, [newQuery, location.pathname]);

  const updateQuery = useCallback((query) => {
    setNewQuery(query);
  }, []);

  return [currentQuery, updateQuery];
};

export const onRouteUpdate = ({ location }) => {
  const scrollPos = window.sessionStorage.getItem(`keep-scroll-qs-${location.pathname}`);
  if (scrollPos) {
    window.scrollTo(0, scrollPos);
    window.sessionStorage.removeItem(`keep-scroll-qs-${location.pathname}`);
  }
};

export default useKeepScroll;
