import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Modal from "./modal";
import { onetrustShowSettings } from "../utility/onetrust";
import Close from "../icons/close-white.svg";

const GROUPS = ["C0003"];
const areCookiesEnabled = () =>
  window.OnetrustActiveGroups && GROUPS.some((item) => window.OnetrustActiveGroups.includes(item));

const YoutubeModal = ({ show, onHide, code }) => {
  const ref = useRef(null);
  const iframeRef = useRef(null);
  const postCommand = useCallback((command) => {
    const iframe = iframeRef.current;
    if (!iframe) return;
    iframe.contentWindow.postMessage(
      JSON.stringify({
        event: "command",
        func: command,
        args: "",
      }),
      "*"
    );
  }, []);

  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (show && ref.current) {
      ref.current.focus();

      if (window.OneTrust && !areCookiesEnabled()) {
        setShowAlert(true);
        window.OneTrust.OnConsentChanged(() => {
          setShowAlert(!areCookiesEnabled());
        });
        return () => setShowAlert(false);
      }

      const iframe = iframeRef.current;
      if (iframe) {
        const playVideo = () => postCommand("playVideo");
        iframe.addEventListener("load", playVideo);
        playVideo();
        return () => iframe.removeEventListener("load", playVideo);
      }
    }
  }, [show, postCommand]);

  const closeDialog = useCallback(() => {
    if (iframeRef.current) {
      // console.log('pause video');
      postCommand("stopVideo");
    }
    onHide();
  }, [onHide, postCommand]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        closeDialog();
      }
    },
    [closeDialog]
  );

  const youtubeUrl = useMemo(() => {
    return show && code
      ? `https://www.youtube.com/embed/${code}?rel=0&enablejsapi=1&version=3?rel=0`
      : "";
  }, [code, show]);

  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        close
        video
        youtubeNotEnabled
        customize
      }
    }
  `);

  return (
    <Modal>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        ref={ref}
        className={`youtube-modal${show ? " youtube-modal--show" : ""}`}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={show ? 0 : -1}
        role="dialog"
        aria-hidden={!show || !youtubeUrl}
        onKeyDown={onKeyDown}
      >
        <div className="youtube-modal__dialog" role="document">
          <button
            className="youtube-modal__close"
            onClick={closeDialog}
            aria-label={labelManagerLabels.close}
          >
            <Close />
          </button>
          {showAlert ? (
            <div className="youtube-modal__alert">
              <div className="youtube-modal__alert-content text-center">
                <div className="youtube-modal__alert-title">
                  {labelManagerLabels.youtubeNotEnabled}
                </div>
                <div className="d-flex flex-column flex-md-row justify-content-center align-items-center mt-3 mt-md-4">
                  <button className="btn-link mb-2 mb-md-0 mr-md-3" onClick={onetrustShowSettings}>
                    {labelManagerLabels.customize}
                  </button>
                  <button
                    className="youtube-modal__alert-close btn-link btn-link--white"
                    onClick={closeDialog}
                  >
                    {labelManagerLabels.close}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <iframe
              ref={iframeRef}
              src={youtubeUrl}
              className="youtube-modal__iframe"
              width="100%"
              title={labelManagerLabels.video}
              allowFullScreen={true}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default YoutubeModal;
