export const isDirectAddToCart = (location) => {
  const params = new URLSearchParams(location?.search);
  return params.get("abtc") === "datc";
};

export const isTagRedOrBlue = (location) => {
  const params = new URLSearchParams(location?.search);
  return params.get("abtc") === "tagrb";
};

// UPDATE 23/06
/* export const isWhereToBuyHide = (location) => {
    const params = new URLSearchParams(location?.search);
    return params.get('abtc') === 'wtbh';
} */
