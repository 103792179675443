import {
  MAGENTO_CART_URL,
  hasMagento,
  queryCart as magentoQueryCart,
  queryProductsDetails as magentoQueryProductsDetails,
  addProductToCart as magentoAddProductToCart,
  addProductToCartWashpass as magentoAddProductToCartWashpass,
  createCart as magentoCreateCart,
  requestUserCart as magentoRequestUserCart,
} from "./magento";
import {
  hasShopify,
  queryCart as shopifyQueryCart,
  queryProductsDetails as shopifyQueryProductsDetails,
  getCartUrl as shopifyGetCartUrl,
  addProductToCart as shopifyAddProductToCart,
  createCart as shopifyCreateCart,
} from "./shopify";
import {
  hasCommerceCloud,
  queryProductsDetails as ccQueryProductsDetails,
  getCartUrl as ccGetCartUrl,
  createBasket,
  getBasket,
  addProductToBasket,
  requestUserBasket,
} from "./commerce-cloud";

export const hasEcommerce = !!hasMagento || !!hasShopify || !!hasCommerceCloud;
export const getCartUrl = async (cartId, userLogged) => {
  if (hasMagento) {
    return userLogged ? MAGENTO_CART_URL : `${MAGENTO_CART_URL}?cartId=${cartId}`;
  } else if (hasShopify) {
    return shopifyGetCartUrl(cartId);
  } else if (hasCommerceCloud) {
    return ccGetCartUrl(cartId);
  }
  return null;
};

export const createCart = async () => {
  if (hasMagento) {
    return magentoCreateCart();
  } else if (hasShopify) {
    return shopifyCreateCart();
  } else if (hasCommerceCloud) {
    return createBasket();
  }
  return null;
};

export const queryCart = async (cartId) => {
  if (hasMagento) {
    return magentoQueryCart(cartId);
  } else if (hasShopify) {
    return shopifyQueryCart(cartId);
  } else if (hasCommerceCloud) {
    return getBasket(cartId);
  }
  return null;
};

export const queryProductsDetails = async (productIds, currency) => {
  if (hasMagento) {
    return magentoQueryProductsDetails(productIds);
  } else if (hasShopify) {
    return shopifyQueryProductsDetails(productIds);
  } else if (hasCommerceCloud) {
    return ccQueryProductsDetails(productIds, currency);
  }
  return [];
};

export const addProductToCart = async (productId, cartId, userLogged) => {
  if (hasMagento) {
    return magentoAddProductToCart(productId, cartId, userLogged);
  } else if (hasShopify) {
    return shopifyAddProductToCart(productId, cartId);
  } else if (hasCommerceCloud) {
    return addProductToBasket(productId, cartId);
  } else {
    return null;
  }
};

export const requestUserCart = async (userData, guestCartId) => {
  if (hasMagento) {
    return magentoRequestUserCart(userData, guestCartId);
  } else if (hasShopify) {
    return shopifyQueryCart(guestCartId);
  } else if (hasCommerceCloud) {
    return requestUserBasket(userData, guestCartId);
  } else {
    return null;
  }
};

export const addProductToCartWashpass = async (productId, cartId, selected_options, userLogged) => {
  if (hasMagento) {
    return magentoAddProductToCartWashpass(productId, cartId, selected_options, userLogged);
  } else if (hasShopify) {
    return null;
  } else {
    return null;
  }
};
