import React, { createContext, useState, useCallback, useMemo, useContext } from "react";
import YoutubeModal from "../components/youtube-modal";
import VideoModal from "../components/video-modal";

const YoutubeContext = createContext({ showVideoModal: () => undefined });

const EMBED_MATCH = /^https:\/\/youtube.com\/embed\/(.+)$/;
const WATCH_MATCH = /watch\?v=(.+)$/;

const getYoutubeCode = (videoId) => {
  const embed = EMBED_MATCH.exec(videoId);
  if (embed?.length) {
    return embed[1];
  }
  const watch = WATCH_MATCH.exec(videoId);
  if (watch?.length) {
    return watch[1];
  }
  return videoId.includes("/") ? "" : videoId.split("/").reverse()[0];
};

export const YoutubeProvider = ({ children }) => {
  const [videoState, setVideoState] = useState({ code: "", url: "", show: false });
  const showVideoModal = useCallback((e) => {
    const { name } = e.currentTarget;
    const code = getYoutubeCode(name);
    const url = !code && name.includes("/") ? name : "";
    setVideoState({ code, url, show: true });
  }, []);
  const closeVideoModal = useCallback(
    () => setVideoState((current) => (current.show ? { ...current, show: false } : current)),
    []
  );
  const contextValue = useMemo(() => {
    return { videoState, showVideoModal, closeVideoModal };
  }, [videoState, showVideoModal, closeVideoModal]);

  return <YoutubeContext.Provider value={contextValue}>{children}</YoutubeContext.Provider>;
};

export const YoutubePageWrapper = ({ children }) => {
  const { videoState, closeVideoModal } = useContext(YoutubeContext);
  return (
    <>
      {children}
      <YoutubeModal
        show={!!videoState.code && videoState.show}
        onHide={closeVideoModal}
        code={videoState.code}
      />
      <VideoModal
        show={!!videoState.url && videoState.show}
        onHide={closeVideoModal}
        url={videoState.url}
      />
    </>
  );
};

export default YoutubeContext;
