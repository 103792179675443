export const reloadOTBanner = () => {
  const otConsentSdk = document.getElementById("onetrust-consent-sdk");
  if (otConsentSdk) {
    otConsentSdk.remove();
  }
  if (window.OneTrust) {
    window.OneTrust.Init();
    setTimeout(() => {
      window.OneTrust.LoadBanner();
      const toggleDisplay = document.getElementsByClassName("ot-sdk-show-settings");
      const handleClick = (event) => {
        event.stopImmediatePropagation();
        window.OneTrust.ToggleInfoDisplay();
      };
      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = handleClick;
      }
    }, 1000);
  }
};

export const onetrustShowSettings = (event) => {
  event?.stopImmediatePropagation
    ? event?.stopImmediatePropagation()
    : event.nativeEvent.stopImmediatePropagation();
  if (window.OneTrust) {
    window.OneTrust.ToggleInfoDisplay();
  }
};

const OT_COOKIE_POLICY_ID =
  process.env.GATSBY_OT_COOKIE_POLICY_ID || "c3046c4b-ffe1-45ab-8f12-5b97680cafa3";

export const initCookiePolicy = async () => {
  const sec = document.getElementById(`otnotice-${OT_COOKIE_POLICY_ID}`);
  if (sec) {
    const OneTrust = await retrieveOneTrust();
    console.log("initCookiePolicy exec");
    const noticeScriptId = "otprivacy-notice-script";
    const noticeScript = document.getElementById(noticeScriptId);
    if (noticeScript) {
      OneTrust.NoticeApi.LoadNotices([
        `https://privacyportalde-cdn.onetrust.com/835df8f9-4bd5-4895-a755-ecc3052ecc69/privacy-notices/${OT_COOKIE_POLICY_ID}.json`,
      ]);
    } else {
      const otNoticeScriptSrc =
        "https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
      OneTrust.InsertScript(
        otNoticeScriptSrc,
        "head",
        () => {
          OneTrust.NoticeApi.Initialized.then(() => {
            OneTrust.NoticeApi.LoadNotices([
              `https://privacyportalde-cdn.onetrust.com/835df8f9-4bd5-4895-a755-ecc3052ecc69/privacy-notices/${OT_COOKIE_POLICY_ID}.json`,
            ]);
          });
        },
        null,
        "1"
      );
      const script = document.querySelector(`script[src="${otNoticeScriptSrc}"]`);
      script.setAttribute("id", noticeScriptId);
      script.textContent +=
        'settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1kZS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"';
    }
    // Aggiunge il listener al pulsante "Personalizza" cookie
    setTimeout(() => {
      const toggleDisplay = document.getElementsByClassName("ot-sdk-show-settings");
      for (let i = 0; i < toggleDisplay.length; i++) {
        toggleDisplay[i].onclick = onetrustShowSettings;
      }
    }, 1000);
  }
};

const OT_PRIVACY_POLICY_ID =
  process.env.GATSBY_OT_PRIVACY_POLICY_ID || "2da9f02c-0f69-4aeb-9fa8-5ec93405e344";

export const initPrivacyPolicy = async () => {
  const sec = document.getElementById(`otnotice-${OT_PRIVACY_POLICY_ID}`);
  if (sec) {
    const OneTrust = await retrieveOneTrust();
    console.log("initPrivacyPolicy exec");
    const noticeScriptId = "otprivacy-notice-script";
    const noticeScript = document.getElementById(noticeScriptId);
    if (noticeScript) {
      OneTrust.NoticeApi.LoadNotices([
        `https://privacyportalde-cdn.onetrust.com/835df8f9-4bd5-4895-a755-ecc3052ecc69/privacy-notices/${OT_PRIVACY_POLICY_ID}.json`,
      ]);
    } else {
      const otNoticeScriptSrc =
        "https://privacyportalde-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
      OneTrust.InsertScript(
        otNoticeScriptSrc,
        "head",
        () => {
          OneTrust.NoticeApi.Initialized.then(() => {
            OneTrust.NoticeApi.LoadNotices([
              `https://privacyportalde-cdn.onetrust.com/835df8f9-4bd5-4895-a755-ecc3052ecc69/privacy-notices/${OT_PRIVACY_POLICY_ID}.json`,
            ]);
          });
        },
        null,
        "1"
      );
      const script = document.querySelector(`script[src="${otNoticeScriptSrc}"]`);
      script.setAttribute("id", noticeScriptId);
      script.textContent +=
        'settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC1kZS5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"';
    }
  }
};

let oneTrustPromise;

export const retrieveOneTrust = () => {
  if (!oneTrustPromise) {
    if (window.OneTrust) {
      oneTrustPromise = Promise.resolve(window.OneTrust);
    } else {
      oneTrustPromise = new Promise((resolve) => {
        let onetrustValue;
        // console.log('OneTrust not defined, adding window listener');
        let onetrustStarted = false;
        Object.defineProperty(window, "OneTrust", {
          set: function (value) {
            if (!onetrustStarted) {
              onetrustStarted = true;
              resolve(value);
            }
            onetrustValue = value;
          },
          get: function () {
            return onetrustValue;
          },
        });
      });
    }
  }
  return oneTrustPromise;
};
