export const productDetailView = (
  babelProdotto,
  priceData,
  origin = `Product Detail page`,
  config
) => {
  if (!window.dataLayer) return;
  try {
    const { key_prod, productCode, pageLine } = babelProdotto;
    const priceObject = priceData?.sellingPrice ? priceData : null;
    const msrpPrice = priceObject?.msrpPrice?.value ? priceObject.msrpPrice.value : null;
    const sellingPrice = priceObject?.sellingPrice?.value ? priceObject.sellingPrice.value : null;
    const omnibusValue =
      priceObject?.omnibusPrice && priceObject?.omnibusPrice?.value
        ? priceObject.omnibusPrice.value
        : null;
    const omnibusPrice =
      !!omnibusValue && typeof omnibusValue === "number"
        ? omnibusValue
        : !!omnibusValue && typeof omnibusValue === "string"
        ? parseFloat(omnibusValue)
        : null;

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item", //per trigger in SPA
      ecommerce: {
        value: sellingPrice,
        currency: config?.currency?.value || "EUR",
        items: [
          {
            item_name: productCode, // Name or ID is required.
            item_id: key_prod,
            price: sellingPrice,
            item_brand: "Haier",
            item_category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
            item_list_name: origin,
            item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
            discount:
              sellingPrice && omnibusPrice && !(omnibusPrice < sellingPrice)
                ? omnibusPrice - sellingPrice
                : sellingPrice && !omnibusPrice && msrpPrice && !(msrpPrice < sellingPrice)
                ? msrpPrice - sellingPrice
                : 0,
            index: 0,
            quantity: 1,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productDetailView: ", err);
  }
};

export const productImpression = (products, origin) => {
  if (!window.dataLayer) return;
  try {
    const list = products.map((item, index) => {
      const { key_prod, productCode, pageLine, priceData } = item;
      const priceObject = priceData?.sellingPrice?.value ? priceData : item;
      const msrpPrice = priceObject?.msrpPrice?.value ? priceObject.msrpPrice.value : null;
      const sellingPrice = priceObject?.sellingPrice?.value ? priceObject.sellingPrice.value : null;
      const omnibusValue =
        priceObject?.omnibusPrice && priceObject?.omnibusPrice?.value
          ? priceObject.omnibusPrice.value
          : null;
      const omnibusPrice =
        !!omnibusValue && typeof omnibusValue === "number"
          ? omnibusValue
          : !!omnibusValue && typeof omnibusValue === "string"
          ? parseFloat(omnibusValue)
          : null;

      return {
        item_name: productCode,
        item_id: key_prod,
        item_brand: "Haier",
        price: sellingPrice,
        item_list_name: origin,
        item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
        index: index,
        quantity: 1,
        item_category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
        discount:
          sellingPrice && omnibusPrice && !(omnibusPrice < sellingPrice)
            ? omnibusPrice - sellingPrice
            : sellingPrice && !omnibusPrice && msrpPrice && !(msrpPrice < sellingPrice)
            ? msrpPrice - sellingPrice
            : 0,
      };
    });

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item_list",
      ecommerce: {
        item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
        item_list_name: origin,
        items: list,
      },
    });
  } catch (err) {
    console.error("Error in productImpression: ", err);
  }
};

export const productFilters = (filterEvent) => {
  if (!window.dataLayer) {
    console.log("filterEvent", filterEvent);
    return;
  }

  try {
    window.dataLayer.push(filterEvent);
  } catch (err) {
    console.error("Error in productFilters: ", err);
  }
};

export const productAddCart = (babelProdotto, priceData, position) => {
  if (!window.dataLayer) return;
  try {
    const { key_prod, productCode, pageLine } = babelProdotto;
    const priceObject = priceData?.sellingPrice ? priceData : babelProdotto;
    const priceInfo = priceObject?.sellingPrice?.value
      ? priceObject.sellingPrice
      : priceObject.msrpPrice;
    const msrpPrice = priceObject?.msrpPrice?.value ? priceObject.msrpPrice.value : null;
    const sellingPrice = priceObject?.sellingPrice?.value ? priceObject.sellingPrice.value : null;
    const omnibusValue =
      priceObject?.omnibusPrice && priceObject?.omnibusPrice?.value
        ? priceObject.omnibusPrice.value
        : null;
    const omnibusPrice =
      !!omnibusValue && typeof omnibusValue === "number"
        ? omnibusValue
        : !!omnibusValue && typeof omnibusValue === "string"
        ? parseFloat(omnibusValue)
        : null;

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currency: priceInfo?.currency || "EUR",
        value: sellingPrice,
        position: position,
        items: [
          {
            //  adding a product to a shopping cart.
            item_name: productCode,
            item_id: key_prod,
            item_brand: "Haier",
            price: sellingPrice,
            index: 0,
            item_category: pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
            quantity: 1,
            discount:
              sellingPrice && omnibusPrice && !(omnibusPrice < sellingPrice)
                ? omnibusPrice - sellingPrice
                : sellingPrice && !omnibusPrice && msrpPrice && !(msrpPrice < sellingPrice)
                ? msrpPrice - sellingPrice
                : 0,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productAddCart: ", err);
  }
};

export const productAddCartWashPass = (babelProdotto, priceData, position) => {
  if (!window.dataLayer) return;
  try {
    const { name, sku, pageLine } = babelProdotto;
    const priceObject = priceData?.sellingPrice ? priceData : babelProdotto;
    const priceInfo = priceObject?.sellingPrice?.value;
    const price = priceInfo?.value;

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "addToCart",
      ecommerce: {
        currency: priceInfo?.currency || "EUR",
        value: price ? price : null,
        position: position,
        items: [
          {
            //  adding a product to a shopping cart.
            item_name: name,
            item_id: sku,
            item_brand: "Haier",
            price: price ? price : null,
            item_category:
              pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName || "washpass",
            quantity: 1,
            index: 0,
            discount: 0,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productAddCart: ", err);
  }
};
export const productClick = (babelProdotto, priceData, origin = "Other", index, currency) => {
  if (!window.dataLayer) return;
  try {
    const { key_prod, productCode, pageLine, category } = babelProdotto;
    const priceObject = priceData?.sellingPrice ? priceData : babelProdotto;
    const msrpPrice = priceObject?.msrpPrice?.value ? priceObject.msrpPrice.value : null;
    const sellingPrice = priceObject?.sellingPrice?.value ? priceObject.sellingPrice.value : null;
    const omnibusValue =
      priceObject?.omnibusPrice && priceObject?.omnibusPrice?.value
        ? priceObject.omnibusPrice.value
        : null;
    const omnibusPrice =
      !!omnibusValue && typeof omnibusValue === "number"
        ? omnibusValue
        : !!omnibusValue && typeof omnibusValue === "string"
        ? parseFloat(omnibusValue)
        : null;

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "select_item",
      ecommerce: {
        item_list_name: origin,
        item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
        items: [
          {
            item_name: productCode,
            item_id: key_prod,
            item_brand: "Haier",
            price: sellingPrice,
            item_list_name: origin,
            item_list_id: origin.toLowerCase().replaceAll(" ", "_"),
            index: index,
            quantity: 1,
            item_category: category
              ? category
              : pageLine?.taxonomyCategoryBriefs?.[0]?.taxonomyCategoryName,
            discount:
              sellingPrice && omnibusPrice && !(omnibusPrice < sellingPrice)
                ? omnibusPrice - sellingPrice
                : sellingPrice && !omnibusPrice && msrpPrice && !(msrpPrice < sellingPrice)
                ? msrpPrice - sellingPrice
                : 0,
          },
        ],
      },
    });
  } catch (err) {
    console.error("Error in productClick: ", err);
  }
};

export const landingImpression = (slug) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: "LP",
      slug,
    });
  } catch (err) {
    console.error("Error in landingImpression: ", err);
  }
};

export const eventImpression = (slug, eventCategory) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: eventCategory,
      slug,
    });
  } catch (err) {
    console.error("Error in eventImpression: ", err);
  }
};

export const eventPdpSliderSlide = (data) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: "pdp-slider-slide",
      data,
    });
  } catch (err) {
    console.error("Error in eventPdpSliderSlide: ", err);
  }
};

export const eventPdpSliderClick = (data) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: "pdp-slider-click",
      data,
    });
  } catch (err) {
    console.error("Error in eventPdpSliderClick: ", err);
  }
};

export const eventInternalLinkClick = (data) => {
  if (!window.dataLayer) return;
  try {
    window.dataLayer.push({
      event: "internal_link_click",
      data,
    });
  } catch (err) {
    console.error("Error in eventInternalLinkClick: ", err);
  }
};
