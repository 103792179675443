import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Modal from "./modal";
import Close from "../icons/close-white.svg";

const VideoModal = ({ show, onHide, url }) => {
  const ref = useRef(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    if (show && ref.current) {
      ref.current.focus();
    }
  }, [show]);

  const closeDialog = useCallback(() => {
    if (iframeRef.current) {
      // console.log('pause video');
      onHide();
    }
  }, [onHide]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.key === "Escape") {
        closeDialog();
      }
    },
    [closeDialog]
  );

  const videoUrl = useMemo(() => (show && url ? url : ""), [show, url]);

  const { labelManagerLabels } = useStaticQuery(graphql`
    {
      labelManagerLabels: labelManagerLabels {
        close
        video
      }
    }
  `);

  return (
    <Modal>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <div
        ref={ref}
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={show ? 0 : -1}
        role="dialog"
        aria-hidden={!show || !videoUrl}
        onKeyDown={onKeyDown}
        className={`youtube-modal youtube-modal--generic${show ? " youtube-modal--show" : ""}`}
      >
        <div className="youtube-modal__dialog" role="document">
          <button
            className="youtube-modal__close"
            onClick={closeDialog}
            aria-label={labelManagerLabels.close}
          >
            <Close />
          </button>
          <iframe
            ref={iframeRef}
            src={videoUrl}
            className="youtube-modal__iframe"
            width="100%"
            title={labelManagerLabels.video}
            allowFullScreen={true}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          />
        </div>
      </div>
    </Modal>
  );
};

export default VideoModal;
